import { EditStepPreFirestore } from '../../../types/firestore/EditStep/Step';

export const mutateDocumentWithStep = <
  TDoc extends Record<string, any>,
  TStep extends EditStepPreFirestore,
>(
  document: TDoc,
  step: TStep,
) => {
  const updatedDocument = Object.assign({}, document);
  let target = updatedDocument;
  const pathParts = step.path.split('.');
  for (let i = 0; i < pathParts.length - 1; i++) {
    target = target[pathParts[Number(i)]];
  }
  const finalKey = pathParts[pathParts.length - 1];

  switch (step.action) {
    case 'overwrite':
      target[finalKey as keyof TDoc] = step.value;
      break;
    case 'deleteKey':
      delete target[String(finalKey)];
      break;
    case 'arrayInsert':
      target?.[String(finalKey)]?.splice(
        step.value.index,
        0,
        step.value.insert,
      );
      break;
    case 'arrayDelete':
      target?.[String(finalKey)]?.splice(step.value.index, 1);
      break;
    default:
      console.error(`Unhandled action type`);
      break;
  }
  return updatedDocument as TDoc;
};
