import { useCallback, useMemo, useState } from 'react';
import { memo } from '../../../util/memo';
import { Game } from '../../../../functions/src/types/firestore/Game';
import { SearchDropdown } from '../catalogs/SearchDropdown';
import { TitleCard } from '../../cards/TitleCard';
import { Hit, OrNode } from '../../../../functions/src/types/Hit';
import { renderHits } from '../../../util/algolia/renderHits';

const BUFFER_BEFORE_FETCH = 4;

export type GameDropdownSearchProps = {
  hits: OrNode<Hit<Game<Date>>>[];
  onLoadMore: () => void;
  onGameSelect: (
    gameHit: (Game<Date> & { objectID: string }) | undefined,
  ) => void;
};

const GameDropdownSearchUnmemoized = ({
  hits,
  onLoadMore,
  onGameSelect,
}: GameDropdownSearchProps) => {
  const [selectedTitle, setSelectedTitle] = useState<string | undefined>(
    undefined,
  );

  const selectGame = useCallback(
    (gameHit: Game<Date> & { objectID: string }) => {
      setSelectedTitle(gameHit.title);
      onGameSelect(gameHit); // May be redundant
    },
    [onGameSelect, setSelectedTitle],
  );

  const Games = useMemo(() => {
    return renderHits(hits, (hit) => {
      const { title } = hit;
      return (
        <TitleCard
          title={title}
          onClick={() => {
            return selectGame(hit);
          }}
        />
      );
    });
  }, [hits, selectGame]);

  return (
    <SearchDropdown
      Content={Games}
      onNearEnd={onLoadMore}
      buffer={BUFFER_BEFORE_FETCH}
      placeholder={'Search for a game...'}
      value={selectedTitle}
      onChange={(e) => {
        const selectedGame = hits.find((hit) => {
          return 'title' in hit && hit.title === e.target.value;
        }) as Hit<Game<Date>> | undefined;
        if (!selectedGame) {
          return;
        }
        onGameSelect(selectedGame);
      }}
    />
  );
};

export const GameDropdownSearch = memo(GameDropdownSearchUnmemoized);
