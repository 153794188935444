import { FC } from 'react';
import { memo } from '../../../util/memo';
import Typography from '@mui/material/Typography';
import { EventFlowBase } from './EventFlowBase';
import { GradientRadioGroup } from '../prize-pools/GradientRadioGroup';
import { useCreateEventContext } from '../../../contexts/organizer/create-event/CreateEventContext';
import { EVENT_TYPES } from 'src/contexts/organizer/create-event/constants';
import { ComingSoonTooltip } from '../../coming-soon/ComingSoonTooltip';
import { OptionalWrapper } from '../../OptionalWrapper';
import { useWizard } from '../../wizard/Wizard';

export const SelectEventType: FC = memo(function SelectEventTypeUnmemoized() {
  const { go } = useWizard();
  const { eventCreationSettings, dispatchEventCreationSettings } =
    useCreateEventContext();
  return (
    <EventFlowBase
      title="Select Event Type"
      subtitle="Choose the type of event you would like to create"
      continueButtonProps={{
        isAsync: false,
        onClick: () => {
          return go('Select Starting Point');
        },
      }}
      Content={
        <GradientRadioGroup
          onValueChange={(index) => {
            return dispatchEventCreationSettings({
              type: 'SET_EVENT_TYPE',
              // eslint-disable-next-line security/detect-object-injection
              payload: EVENT_TYPES[index],
            });
          }}
          selectedIndex={EVENT_TYPES.indexOf(eventCreationSettings.eventType)}
          items={EVENT_TYPES.map((type) => {
            const isDisabled = type !== 'Tournament';
            return {
              key: type,
              label: (
                <OptionalWrapper
                  doWrap={isDisabled}
                  Wrapper={
                    <ComingSoonTooltip title="Other event types">
                      <></>
                    </ComingSoonTooltip>
                  }
                >
                  <Typography
                    variant="subtitle1"
                    color={isDisabled ? 'text.disabled' : 'text.primary'}
                  >
                    {type}
                  </Typography>
                </OptionalWrapper>
              ),
              disabled: isDisabled,
            };
          })}
        />
      }
    />
  );
});
