import { useCallback } from 'react';
import { useEditable } from '../../contexts/organizer/EditableStepsContext';
import { useRouter } from '../routing/useRouter';
import { useWizard } from '../../components/wizard/Wizard';

export const useCreateTournament = () => {
  const { setDocumentPath: setEditableDocumentPath } = useEditable();
  const { go } = useWizard();
  const { push } = useRouter();
  const closeDialog = useCallback(() => {
    go(undefined);
  }, [go]);
  const createTournament = useCallback(
    async (gameId: string, previousId?: string) => {
      const { createTemplateTournament } = await import(
        '../../firebaseCloud/tournament/organizer/createTemplateTournament'
      );
      const {
        data: { tournamentNew },
      } = await createTemplateTournament({
        gameId,
        previousId,
      });
      const { id: newEventId, gameId: newGameId } = tournamentNew;
      const documentPath = `Game/${newGameId}/Tournament/${newEventId}`;

      setEditableDocumentPath(documentPath);
      push(`/?event=${documentPath}&edit-mode=primary`);
      closeDialog();
    },
    [closeDialog, push, setEditableDocumentPath],
  );

  return { createTournament };
};
