import { memo } from '../../util/memo';
import { RadioProps } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { GradientIcon } from './GradientIcon';

export type GradientRadioProps = RadioProps & {
  gradientColor?: string;
};

export const GradientRadio = memo(function GradientRadioUnmemoized({
  gradientColor = 'primary.verticalInverse',
  ...rest
}: GradientRadioProps) {
  return (
    <Radio
      checkedIcon={
        <GradientIcon
          gradientColor={gradientColor}
          IconComponent={RadioButtonCheckedIcon}
        />
      }
      icon={
        <GradientIcon
          gradientColor={gradientColor}
          IconComponent={RadioButtonUncheckedIcon}
        />
      }
      {...rest}
    />
  );
});
